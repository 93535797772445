<template>
   <div class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>AIOT</el-breadcrumb-item>
            <el-breadcrumb-item>耗能管理</el-breadcrumb-item>
            <el-breadcrumb-item>用电管理</el-breadcrumb-item>
            <el-breadcrumb-item>用电报警</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>酒店：</label>
                  <el-select v-model="hotelId"  class="width-220" :placeholder="$t('msg.select')">
                     <el-option
                           v-for="item in hotelList"
                           :key="item.id"
                           :label="item.hotelName"
                           :value="item.id"
                     ></el-option>
                  </el-select>
               </el-row>
               <el-row class="search-item">
                  <label>类别：</label>
                  <el-select v-model="warn"  class="width-220" :placeholder="$t('msg.select')">
                     <el-option
                           v-for="item in warnTypes"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id"
                     ></el-option>
                  </el-select>
               </el-row>
               <el-button class="bg-gradient" type="primary"  @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
               <el-button type="primary"  plain @click="handleQuery(false)"><span v-text="$t('msg.reset')">重置</span></el-button>
            </el-row>
         </el-row>
         <!-- 主体内容 -->
         <div class="table-box">
            <el-table
                  ref="multipleTable"
                  :data="tableData"
                  :stripe="true"

                  border fit
                  style="width: 100%">
               <el-table-column label="编号" width="120">
                  <template slot-scope="scope">{{scope.row.number}}</template>
               </el-table-column>
               <el-table-column label="报警类别" width="140">
                  <template slot-scope="scope">{{scope.row.category}}</template>
               </el-table-column>
               <el-table-column prop='zoom' label="所在房间" width="160">
                  <template slot-scope="scope">{{scope.row.zoom}}</template>
               </el-table-column>
               <el-table-column label="创建时间">
                  <template slot-scope="scope">{{scope.row.time}}</template>
               </el-table-column>
               <el-table-column  label="所属酒店">
                  <template slot-scope="scope">{{scope.row.hotel}}</template>
               </el-table-column>
               <el-table-column label="状态">
                  <template slot-scope="scope">
                     <span>{{scope.row.status | filterStatus}}</span>
                  </template>
               </el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination :total="total" :page-size="limit" />
         </div>
      </el-row>
   </div>
</template>

<script>
import { urlObj } from "@/api/interface";
export default {
  data() {
    return {
      tableData: [
        {
          number: "1",
          category: "漏电报警",
          zoom: "1001",
          time: "2019年12月10日 11:00",
          hotel: "维也纳清湖店",
          status:"1"
        },
        {
          number: "2",
          category: "短路报警",
          zoom: "1002",
          time: "2019年12月10日 11:00",
          hotel: "维也纳清湖店",
          status:"2"
        },
        {
          number: "3",
          category: "负载报警",
          zoom: "1003",
          time: "2019年12月10日 11:00",
          hotel: "维也纳清湖店",
          status:"2"
        },
        {
          number: "4",
          category: "打火报警",
          zoom: "1004",
          time: "2019年12月10日 11:00",
          hotel: "维也纳清湖店",
          status:"2"
        },
      ], // 表格数据
      loading: false, // 加载中效果
      total: 10,
      limit: 1,
      dateValue: "", // 日期
      hotelId: '',
      hotelList: [
        { hotelName: "达易住大酒店", id: 1 },
      ],
      warn: '',
      warnTypes: [
        { name: "漏电报警", id: 0 },
        { name: "短路报警", id: 1 },
        { name: "负载报警", id: 2 },
        { name: "打火报警", id: 3 },
      ],
    };
  },
  mounted() {},
  methods: {
    // 搜索列表
    handleQuery(){}
  },
  filters:{
    filterStatus(val){
      if(val == 1){
        return "已处理"
      }
      if(val == 2){
        return "待处理"
      }
    }
  }
};
</script>

<style scoped></style>
