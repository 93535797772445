import { render, staticRenderFns } from "./electricityWarn.vue?vue&type=template&id=5dd6d3f6&scoped=true&"
import script from "./electricityWarn.vue?vue&type=script&lang=js&"
export * from "./electricityWarn.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dd6d3f6",
  null
  
)

export default component.exports